// @ts-ignore
import { useConfig } from "base-shell/lib/providers/Config";
import ResponsiveMenu from "../ResponsiveMenu";

// copied from https://github.com/TarikHuber/react-most-wanted/tree/master/packages/material-ui-shell

const Menu = () => {
  const { appConfig } = useConfig();
  const { menu } = appConfig || {};
  const { MenuHeader, MenuContent } = menu || {};

  return (
    <ResponsiveMenu>
      {MenuHeader && <MenuHeader />}
      {MenuContent && <MenuContent />}
    </ResponsiveMenu>
  );
};

export default Menu;
