import React from "react";
import App from "base-shell/lib";
import _config from "./config/config";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";

export default function AppComponent() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
        {/*<SnackbarProvider maxSnack={5}>*/}
        <App config={_config} />
        {/*</SnackbarProvider>*/}
      </LocalizationProvider>
    </>
  );
}
