import React, { lazy } from "react";
import { routePaths } from "./routePaths";

const PrivateRoute = lazy(
  () => import("../components/Authentication/AuthorizedRoute")
);
const ForwardChatwootPage = lazy(() => import("../pages/ForwardChatwoot"));
const DashboardPage = lazy(() => import("../pages/Dashboard/DashboardPage"));
const DialogDemo = lazy(() => import("../pages/DialogDemo/DialogDemo"));
const AutomatedPostsPage = lazy(
  () => import("../pages/SendPush/pages/AutomatedPostsPage")
);
const PostPage = lazy(() => import("../pages/SendPush/pages/PostPage"));

const PostsPage = lazy(() => import("../pages/Posts/pages/PostsListPage"));
const PostEditorPage = lazy(
  () => import("../pages/Posts/pages/PostEditorPage")
);
const WebsiteSiteBuilderPage = lazy(
  () => import("../pages/Website/pages/WebsiteSiteBuilderPage")
);
const WebsiteFooterBuilderPage = lazy(
  () => import("../pages/Website/pages/WebsiteFooterBuilderPage")
);
const WebsiteBuildPage = lazy(
  () => import("../pages/Website/pages/WebsiteBuildPage")
);
const WebsiteMenuPage = lazy(
  () => import("../pages/Website/pages/WebsiteMenuPage")
);
const WebsiteSitesPage = lazy(
  () => import("../pages/Website/pages/WebsiteSitesPage")
);
const PluginsCookieBannerPage = lazy(
  () => import("../pages/Plugins/pages/PluginsCookieBannerPage")
);
const PluginsQrCodeGeneratorPage = lazy(
  () => import("../pages/Plugins/pages/PluginsQrCodeGeneratorPage")
);
const PluginsCustomDomainPage = lazy(
  () => import("../pages/Plugins/pages/PluginsCustomDomainPage")
);
const PluginsWhatsappPage = lazy(
  () => import("../pages/Plugins/pages/PluginsWhatsappPage")
);
const PluginsNewsfeedPage = lazy(
  () => import("../pages/Plugins/pages/PluginsNewsfeedPage")
);
const PluginsLoyaltyPage = lazy(
  () => import("../pages/Plugins/pages/PluginsLoyaltyPage")
);
const SettingsOrganizationPage = lazy(
  () => import("../pages/Settings/pages/SettingsOrganizationPage")
);
const SettingsProjectPage = lazy(
  () => import("../pages/Settings/pages/SettingsProjectPage")
);
const SettingsUserPage = lazy(
  () => import("../pages/Settings/pages/SettingsUserPage")
);
const SettingsLoyaltyPage = lazy(
  () => import("../pages/Settings/pages/SettingsLoyaltyPage")
);
const SettingsOrganizationMembersPage = lazy(
  () => import("../pages/Settings/pages/SettingsOrganizationMembersPage")
);

/* CUSTOMER  */
const LoyaltyCustomerListPage = lazy(
  () => import("../pages/Loyalty/pages/LoyaltyCustomerListPage")
);
const LoyaltyCustomerDetailPage = lazy(
  () => import("../pages/Loyalty/pages/LoyaltyCustomerDetailsPage")
);
const LoyaltyCustomerLogsPage = lazy(
  () => import("../pages/Loyalty/pages/LoyaltyCustomerLogsPage")
);

/* REWARDS */
const RewardListPage = lazy(
  () => import("../pages/Rewards/pages/RewardListPage")
);
const RewardCreatePage = lazy(
  () => import("../pages/Rewards/pages/RewardCreatePage")
);
const RewardDetailsPage = lazy(
  () => import("../pages/Rewards/pages/RewardDetailsPage")
);
const RewardEditPage = lazy(
  () => import("../pages/Rewards/pages/RewardEditPage")
);

/* COUPONS */
const CouponListPage = lazy(
  () => import("../pages/Coupons/pages/CouponListPage")
);
const CouponCreatePage = lazy(
  () => import("../pages/Coupons/pages/CouponCreatePage")
);

/* LOYALTY */
const LoyaltySetupPage = lazy(
  () => import("../pages/Loyalty/pages/LoyaltySetupPage")
);

const LoginPage = lazy(() => import("../pages/LoginPage"));

const routes = [
  {
    // currently only for org switching
    path: routePaths.login,
    exact: true,
    element: (
      <PrivateRoute>
        <LoginPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.home,
    exact: true,
    element: (
      <PrivateRoute>
        <DashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.chat,
    exact: true,
    element: (
      <PrivateRoute>
        <ForwardChatwootPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.postManually,
    exact: true,
    element: (
      <PrivateRoute>
        <PostPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.postAutomatedPosts,
    exact: true,
    element: (
      <PrivateRoute>
        <AutomatedPostsPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.posts,
    exact: true,
    element: (
      <PrivateRoute>
        <PostsPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.postDetails(":postId"),
    exact: true,
    element: (
      <PrivateRoute>
        <PostEditorPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.websiteMenu,
    exact: true,
    element: (
      <PrivateRoute>
        <WebsiteMenuPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.websiteBuild,
    exact: true,
    element: (
      <PrivateRoute>
        <WebsiteBuildPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.websiteSites,
    exact: true,
    element: (
      <PrivateRoute>
        <WebsiteSitesPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.websiteFooter,
    exact: true,
    element: (
      <PrivateRoute>
        <WebsiteFooterBuilderPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.websiteSite(":siteId"),
    exact: true,
    element: (
      <PrivateRoute>
        <WebsiteSiteBuilderPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.pluginsCookieBanner,
    exact: true,
    element: (
      <PrivateRoute>
        <PluginsCookieBannerPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.pluginsQrCodeGenerator,
    exact: true,
    element: (
      <PrivateRoute>
        <PluginsQrCodeGeneratorPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.pluginsCustomDomain,
    exact: true,
    element: (
      <PrivateRoute>
        <PluginsCustomDomainPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.pluginsWhatsapp,
    exact: true,
    element: (
      <PrivateRoute>
        <PluginsWhatsappPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.pluginsNewsfeed,
    exact: true,
    element: (
      <PrivateRoute>
        <PluginsNewsfeedPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.settingsOrganization,
    exact: true,
    element: (
      <PrivateRoute>
        <SettingsOrganizationPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.settingsOrganizationMembers,
    exact: true,
    element: (
      <PrivateRoute>
        <SettingsOrganizationMembersPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.settingsProject,
    exact: true,
    element: (
      <PrivateRoute>
        <SettingsProjectPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.settingsUser,
    exact: true,
    element: (
      <PrivateRoute>
        <SettingsUserPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.settingsLoyalty,
    exact: true,
    element: (
      <PrivateRoute>
        <SettingsLoyaltyPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/dialog_demo",
    exact: true,
    element: (
      <PrivateRoute>
        <DialogDemo />
      </PrivateRoute>
    ),
  },

  /* LOYALTY */
  // {
  //   path: routePaths.customers,
  //   exact: true,
  //   element: (
  //     <PrivateRoute>
  //       <LoyaltyDashboardPage />
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: routePaths.loyaltySetup,
    exact: true,
    element: (
      <PrivateRoute>
        <LoyaltySetupPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.customerList,
    exact: true,
    element: (
      <PrivateRoute>
        <LoyaltyCustomerListPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.customerOverview(":id"),
    exact: true,
    element: (
      <PrivateRoute>
        <LoyaltyCustomerDetailPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.customerLogs(":id"),
    exact: true,
    element: (
      <PrivateRoute>
        <LoyaltyCustomerLogsPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.rewards,
    exact: true,
    element: (
      <PrivateRoute>
        <RewardListPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.rewardsCreate,
    exact: true,
    element: (
      <PrivateRoute>
        <RewardCreatePage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.rewardsDetails(":id"),
    exact: true,
    element: (
      <PrivateRoute>
        <RewardDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.rewardsEdit(":id"),
    exact: true,
    element: (
      <PrivateRoute>
        <RewardEditPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.coupons,
    exact: true,
    element: (
      <PrivateRoute>
        <CouponListPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.couponsCreate,
    exact: true,
    element: (
      <PrivateRoute>
        <CouponCreatePage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.pluginsLoayalty,
    exact: true,
    element: (
      <PrivateRoute>
        <PluginsLoyaltyPage />
      </PrivateRoute>
    ),
  },
];

export default routes;
