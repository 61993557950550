export const routePaths = {
  home: "/",
  login: "/login",
  chat: "/chatwoot",
  postManually: "/post/post",
  postAutomatedPosts: "/post/automated-posts",
  website: "/website",
  websiteMenu: "/website/menu",
  websiteSites: "/website/sites",
  websiteSite: (siteId: string) => `/website/sites/${siteId}`,
  websiteFooter: "/website/footer",
  websiteBuild: "/website/build",
  websiteSiteManager: "/website/site-manager",
  pluginsQrCodeGenerator: "/plugins/qr-code-generator",
  pluginsWhatsapp: "/plugins/whatsapp",
  pluginsCustomDomain: "/plugins/custom-domain",
  pluginsCookieBanner: "/plugins/cookie-banner",
  pluginsNewsfeed: "/plugins/newsfeed",
  pluginsLoayalty: "/plugins/loyalty",
  posts: "/posts",
  postDetails: (postId: string) => `/posts/${postId}`,
  settingsOrganization: "/einstellungen/organisation",
  settingsOrganizationMembers: "/einstellungen/organisation/mitglieder",
  settingsLoyalty: "/einstellungen/bonusprogramm",
  settingsUser: "/einstellungen/benutzer",
  settingsProject: "/einstellungen/projekt",
  customerList: "/kunden",
  customerOverview: (customerId: string) => `/kunden/${customerId}`,
  customerLogs: (customerId: string) => `/kunden/${customerId}/historie`,
  rewards: "/praemien",
  rewardsCreate: "/praemien/neu",
  rewardsDetails: (rewardId: string) => `/praemien/${rewardId}`,
  rewardsEdit: (rewardId: string) => `/praemien/${rewardId}/bearbeiten`,
  coupons: "/codes",
  couponsCreate: "/codes/neu",
  loyaltySetup: "/bonusprogramm/setup",
};
